import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import axios from 'axios';
import { formatNumber, formatCrypto } from '../../utils/helper';
import { Dropdown } from 'react-bootstrap';
import { setSelectedFiatPair } from '../../redux/coinSlice';
import TradeQuoteSellModal from '../Modals/TradeQuoteSellModal';

export default function SellForm() {
    const dispatch = useDispatch();
    const [tradeData, setTradeData] = useState({
        coin1: '',
        coin2: '',
        rate: 0,
    });

    const { coin1, coin2, rate } = tradeData;
    const [coin1DropdownOpen, setCoin1DropdownOpen] = useState(false);
    const [coin2DropdownOpen, setCoin2DropdownOpen] = useState(false);
    const handleCoin1Toggle = (isOpen) => {
        setCoin1DropdownOpen(isOpen);
        if (isOpen) {
            setCoin2DropdownOpen(false);
        }
    };

    const handleCoin2Toggle = (isOpen) => {
        setCoin2DropdownOpen(isOpen);
        if (isOpen) {
            setCoin1DropdownOpen(false);
        }
    };

    const { tradePairs, selectedFiatPair } = useSelector(state => state.coin);

    const filteredCoin2Pairs = tradePairs.filter(pair =>
        pair.coin1.shortCode === selectedFiatPair?.coin1?.shortCode &&
        pair.pairType === "fiat"
    );

    const filteredCoin1Pairs = tradePairs.filter(pair =>
        pair.coin2.shortCode === selectedFiatPair?.coin2?.shortCode &&
        pair.pairType === "fiat"
    );

    const defaultFiatPair = selectedFiatPair ||
        (tradePairs && tradePairs.length > 0 && tradePairs.find(pair => pair.pairType === 'fiat' && pair.default)) ||
        (tradePairs && tradePairs[0]);

    const [adjustedCoin1Price, setAdjustedCoin1Price] = useState(0);
    const [adjustedCoin2Price, setAdjustedCoin2Price] = useState(0);
    const [gasFees, setGasFees] = useState(0);
    const [isCalculating, setIsCalculating] = useState(false);

    const fetchAdjustedPrice = async (symbol) => {
        try {
            const response = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=USDT`);
            return response.data['USDT'];
        } catch (error) {
            console.error('Error fetching prices:', error);
            return 0;
        }
    };

    const updatePrices = async () => {
        const fiatCoin1Price = await fetchAdjustedPrice(defaultFiatPair?.coin1?.shortCode); // ZAR/USDT
        const fiatCoin2Price = await fetchAdjustedPrice(defaultFiatPair?.coin2?.shortCode); // SOL/USDT
        setAdjustedCoin1Price(fiatCoin1Price);
        setAdjustedCoin2Price(fiatCoin2Price);
        // Corrected formula: How much ZAR for 1 SOL
        const originalRate = fiatCoin2Price / fiatCoin1Price;
        setTradeData(prevState => ({
            ...prevState,
            rate: originalRate.toFixed(6),
        }));
    };


    useEffect(() => {
        updatePrices();
    }, [defaultFiatPair]);

    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);

    const fetchGasFees = async (symbol, amount) => {
        try {
            const payload = {
                assetId: symbol,
                amount: amount
            }
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/transaction/get_estimatedFee`,
                payload,
                { withCredentials: true }
            );
            return response.data?.estimatedFee.high?.networkFee || 0;
        } catch (error) {
            console.error('Error fetching gas fees:', error);
            return 0;
        }
    };

    const debouncedCalculateCoin2Value = useMemo(() => debounce(async (coin1Amount) => {
        if (coin1Amount && adjustedCoin1Price && adjustedCoin2Price) {
            setIsCalculating(true);
            try {
                const gasFeeForCoin1 = await fetchGasFees(selectedFiatPair?.coin2?.testnetShortCode, coin1Amount);


                // Convert gas fee to ZAR
                const gasFeeInZAR = gasFeeForCoin1 * (adjustedCoin1Price / adjustedCoin2Price);

                // Calculate SOL amount (before fees)
                const coin2AmountOriginal = (coin1Amount * adjustedCoin2Price) / adjustedCoin1Price;

                // Apply gas fees
                const amountAfterGasFees = coin2AmountOriginal - gasFeeInZAR;

                // Apply platform fee (1%)
                const platformFee = amountAfterGasFees * 0.01;
                setGasFees(gasFeeInZAR + platformFee);
                const finalAmount = amountAfterGasFees - platformFee;

                setTradeData(prevState => ({
                    ...prevState,
                    coin2: finalAmount.toFixed(2),
                    gasFeesZAR: gasFeeInZAR.toFixed(2) // Store gas fee in ZAR
                }));
            } catch (error) {
                console.error('Error calculating values:', error);
            } finally {
                setIsCalculating(false);
            }
        } else {
            setTradeData(prevState => ({
                ...prevState,
                coin2: '',
                gasFeesZAR: ''
            }));
        }
    }, 1000), [adjustedCoin1Price, adjustedCoin2Price, selectedFiatPair]);


    useEffect(() => {
        if (coin1) {
            debouncedCalculateCoin2Value(parseFloat(coin1));
        } else {
            setTradeData(prevState => ({
                ...prevState,
                coin2: ''
            }));
        }
        return () => {
            debouncedCalculateCoin2Value.cancel();
        };
    }, [coin1, debouncedCalculateCoin2Value]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        const numericValue = value.replace(/,/g, '');
        setTradeData({
            ...tradeData,
            [id]: numericValue
        });
    };

    const handleCoinSelect = (coin, pair) => {
        dispatch(setSelectedFiatPair(pair));
    };

    return (
        <form>
            <div className="text-center mx-2">
                <h5 className="font-bold">{`Sell ${defaultFiatPair?.coin2.shortCode} with ${defaultFiatPair?.coin1.shortCode}`}</h5>
            </div>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-grow-1">
                    <label htmlFor="coin1">Pay</label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id='coin1'
                            name='coin1'
                            placeholder="0"
                            value={coin1}
                            onChange={handleInputChange}
                            step="any"
                        />
                        {filteredCoin2Pairs.length === 1 ? (
                            <button
                                className="input-group-text d-flex align-items-center cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleCoinSelect('coin2', filteredCoin2Pairs[0]);
                                    setCoin2DropdownOpen(false);
                                }}
                            >
                                <img
                                    alt={filteredCoin2Pairs[0].coin2.name}
                                    className="img-fluid coin-symbol me-1"
                                    src={filteredCoin2Pairs[0].coin2.icon}
                                />
                                {filteredCoin2Pairs[0].coin2.shortCode}
                            </button>
                        ) : (
                            <Dropdown
                                className="input-group-text"
                                show={coin2DropdownOpen}
                                onToggle={handleCoin2Toggle}
                            >
                                <Dropdown.Toggle
                                    variant="basic"
                                    id="coin-dropdown"
                                    className="d-flex align-items-center cursor-pointer"
                                >
                                    <img
                                        alt={selectedFiatPair?.coin2?.name}
                                        className="img-fluid coin-symbol me-1"
                                        src={selectedFiatPair?.coin2?.icon}
                                    />
                                    {selectedFiatPair?.coin2?.shortCode}
                                </Dropdown.Toggle>
                                {filteredCoin2Pairs.length > 0 && (
                                    <Dropdown.Menu>
                                        {filteredCoin2Pairs.map(pair => (
                                            <Dropdown.Item
                                                as="button"
                                                key={pair.coin2.shortCode}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleCoinSelect('coin2', pair);
                                                    setCoin2DropdownOpen(false);
                                                }}
                                            >
                                                <img
                                                    alt={pair.coin2.name}
                                                    className="img-fluid coin-symbol me-1"
                                                    src={pair.coin2.icon}
                                                />
                                                {pair.coin2.shortCode}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-grow-1">
                    <label htmlFor="coin2">Receive</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            id='coin2'
                            name='coin2'
                            placeholder="0"
                            value={isCalculating ? 'Calculating...' : (coin2 ? formatNumber(parseFloat(coin2)) : '')}
                            step="any"
                            readOnly
                        />
                        {filteredCoin1Pairs.length === 1 ? (
                            <button
                                className="input-group-text d-flex align-items-center cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleCoinSelect('coin1', filteredCoin1Pairs[0]);
                                    setCoin1DropdownOpen(false);
                                }}
                            >
                                <img
                                    alt={filteredCoin1Pairs[0].coin1.name}
                                    className="img-fluid coin-symbol me-1"
                                    src={filteredCoin1Pairs[0].coin1.icon}
                                />
                                {filteredCoin1Pairs[0].coin1.shortCode}
                            </button>
                        ) : (
                            <Dropdown
                                className="input-group-text"
                                show={coin1DropdownOpen}
                                onToggle={handleCoin1Toggle}
                            >
                                <Dropdown.Toggle
                                    variant="basic"
                                    id="coin-dropdown"
                                    className="d-flex align-items-center cursor-pointer"
                                >
                                    <img
                                        alt={selectedFiatPair?.coin1?.name}
                                        className="img-fluid coin-symbol me-1"
                                        src={selectedFiatPair?.coin1?.icon}
                                    />
                                    {selectedFiatPair?.coin1?.shortCode}
                                </Dropdown.Toggle>
                                {filteredCoin1Pairs.length > 0 && (
                                    <Dropdown.Menu>
                                        {filteredCoin1Pairs.map(pair => (
                                            <Dropdown.Item
                                                as="button"
                                                key={pair.coin1.shortCode}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleCoinSelect('coin1', pair);
                                                    setCoin1DropdownOpen(false);
                                                }}
                                            >
                                                <img
                                                    alt={pair.coin1.name}
                                                    className="img-fluid coin-symbol me-1"
                                                    src={pair.coin1.icon}
                                                />
                                                {pair.coin1.shortCode}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between mb-2">
                <div className="fw-lighter">Network Fees (Included)</div>
                <div>
                    {isCalculating ? (
                        <span className="placeholder-glow">
                            <span className="placeholder col-4"></span>
                        </span>
                    ) : (
                        gasFees > 0 && `${formatCrypto(parseFloat(gasFees))} ${selectedFiatPair?.coin1?.shortCode}`
                    )}
                </div>
            </div>
            <div>
                <button
                    type="button"
                    onClick={toggleModal}
                    className="btn btn-info w-100 mb-2"
                    disabled={coin1 <= 0 || !coin2 || isCalculating}
                >
                    {isCalculating ? (
                        <span>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Calculating...
                        </span>
                    ) : (
                        'Get Exchange Quote'
                    )}
                </button>
            </div>
            <TradeQuoteSellModal
                show={showModal}
                onHide={toggleModal}
                coin1={selectedFiatPair?.coin2}
                coin2={selectedFiatPair?.coin1}
                coin1Amount={coin1}
                coin2Amount={coin2}
                gasFees={gasFees}
                rate={rate}
            />
        </form>
    );
}