import { lazy } from 'react';
import { Route, Routes } from "react-router-dom";
import Layouts from './layouts';
import BankDepositSuccess from './pages/BankDeposit/BankDepositSuccess';
import BankDepositCancel from './pages/BankDeposit/BankDepositCancel';

// Public Pages
import Home from './pages/Home/Home';
import UserWallet from './pages/User/UserWallet';
import BankWithdrawRequests from './pages/wallets/Withdraw/BankWithdrawRequests';
import BankDeposit from './pages/BankDeposit/BankDeposit';
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const BuySell = lazy(() => import('./pages/BuySell/BuySell'));
const Institutions = lazy(() => import('./pages/Institutions/Institutions'));
const OTC = lazy(() => import('./pages/OTC/OTC'));
const InterestAccount = lazy(() => import('./pages/InterestAccount/InterestAccount'));
const SignIn = lazy(() => import('./pages/Auth/SignIn'));
const Signup = lazy(() => import('./pages/Auth/Signup'));
const VerifyEmail = lazy(() => import('./utils/VerifyEmail'));
const Forgot = lazy(() => import('./pages/Auth/Forgot'));
const ResendVerification = lazy(() => import('./pages/Auth/ResendVerification'));
const Blogs = lazy(() => import('./pages/Blogs/Blogs'));
const BlogDetails = lazy(() => import('./pages/Blogs/BlogDetails'));
const ContactUs = lazy(() => import('./pages/Contact/ContactUs'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions/TermsAndConditions'));
const ComplianceDisclosures = lazy(() => import('./pages/ComplianceDisclosures/ComplianceDisclosures'));
const Careers = lazy(() => import('./pages/Careers/Careers'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const PAIAManual = lazy(() => import('./pages/PAIAManual/PAIAManual'));
const PAIAStatement = lazy(() => import('./pages/PAIAStatement/PAIAStatement'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));

// Client Pages
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const KYC = lazy(() => import('./pages/User/KYC'));
const CryptoDeposit = lazy(() => import('./pages/wallets/Deposit/CryptoDeposit'));
const BuyCrypto = lazy(() => import('./pages/wallets/Deposit/BuyCrypto'));
const Withdraw = lazy(() => import('./pages/wallets/Withdraw/Withdraw'));
const TransactionsHistory = lazy(() => import('./pages/history/TransactionsHistory'));
const EditProfile = lazy(() => import('./pages/Profile/EditProfile'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const BankWithdraw = lazy(() => import('./pages/wallets/Withdraw/BankWithdraw'));

// Admin Pages
const AdminDashboard = lazy(() => import('./pages/dashboard/AdminDashboard'));
const Coins = lazy(() => import('./pages/Coins/Coins'));
const AddCoin = lazy(() => import('./pages/Coins/AddCoin'));
const EditCoin = lazy(() => import('./pages/Coins/EditCoin'));
const TradePairs = lazy(() => import('./pages/Coins/TradePairs'));
const Users = lazy(() => import('./pages/User/Users'));
const UserLogs = lazy(() => import('./pages/User/UserLogs'));
const Admins = lazy(() => import('./pages/Admins/Admins'));
const AddAdmin = lazy(() => import('./pages/Admins/AddAdmin'));
const UserKYCList = lazy(() => import('./components/KYC/UserKYCList'));
const AdminTransactionHistory = lazy(() => import('./pages/history/AdminTransactionHistory'));

// Define routes arrays
export const publicRoutes = [
    { path: '/', element: <Home /> },
    { path: '/ae', element: <Home /> },
    { path: '/eu', element: <Home /> },
    { path: '/about-us', element: <AboutUs /> },
    { path: '/buy-and-sell', element: <BuySell /> },
    { path: '/institutions', element: <Institutions /> },
    { path: '/otc', element: <OTC /> },
    { path: '/interest-accounts', element: <InterestAccount /> },
    { path: '/signin', element: <SignIn /> },
    { path: '/signup', element: <Signup /> },
    { path: '/verify-email', element: <VerifyEmail /> },
    { path: '/forgot', element: <Forgot /> },
    { path: '/resend-verify', element: <ResendVerification /> },
    { path: '/blogs', element: <Blogs /> },
    { path: '/blogs/:slug', element: <BlogDetails /> },
    { path: '/contact-us', element: <ContactUs /> },
    { path: '/terms-of-service', element: <TermsAndConditions /> },
    { path: '/disclosure-statement', element: <ComplianceDisclosures /> },
    { path: '/careers', element: <Careers /> },
    { path: '/reset-password/:token', element: <ResetPassword /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/paia-manual', element: <PAIAManual /> },
    { path: '/paia-statement', element: <PAIAStatement /> },
    { path: '*', element: <NotFound /> },
];

export const clientRoutes = [
    { path: '/client/dashboard', element: <Dashboard /> },
    { path: '/client/kyc', element: <KYC /> },
    { path: '/client/deposit', element: <CryptoDeposit /> },
    { path: '/client/bank-deposit', element: <BankDeposit /> },
    { path: '/client/bank-deposit-success', element: <BankDepositSuccess /> },
    { path: '/client/bank-deposit-cancel', element: <BankDepositCancel /> },
    { path: '/client/buy-crypto', element: <BuyCrypto /> },
    { path: '/client/withdraw', element: <Withdraw /> },
    { path: '/client/history/transactions', element: <TransactionsHistory /> },
    { path: '/client/profile-update', element: <EditProfile /> },
    { path: '/client/settings', element: <Settings /> },
    { path: '/client/bank-withdraw', element: <BankWithdraw /> },
];

export const adminRoutes = [
    { path: '/admin/dashboard', element: <AdminDashboard /> },
    { path: '/admin/coins', element: <Coins /> },
    { path: '/admin/coins/add', element: <AddCoin /> },
    { path: '/admin/coins/edit/:id', element: <EditCoin /> },
    { path: '/admin/trade-pairs', element: <TradePairs /> },
    { path: '/admin/users', element: <Users /> },
    { path: '/admin/user-logs/:userId', element: <UserLogs /> },
    { path: '/admin/admins', element: <Admins /> },
    { path: '/admin/admins/add', element: <AddAdmin /> },
    { path: '/admin/kyc', element: <UserKYCList /> },
    { path: '/admin/user/:id', element: <UserWallet /> },
    { path: '/admin/bank-withdraw-requests', element: <BankWithdrawRequests /> },
    { path: '/admin/transaction-history', element: <AdminTransactionHistory /> },
];

// Final Routes Component
export default function AppRoutes() {
    return (
        <Routes>
            {/* Public Routes */}
            <Route element={<Layouts.Public />}>
                {publicRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                ))}
            </Route>

            {/* Client Routes */}
            <Route element={<Layouts.Client />}>
                {clientRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                ))}
            </Route>

            {/* Admin Routes */}
            <Route element={<Layouts.Admin />}>
                {adminRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                ))}
            </Route>

            {/* Catch-all Route */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
