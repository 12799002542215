import React, { useEffect, useState } from 'react';
import { Card, Alert, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import { useTextClass } from '../../hooks/useTextClass';
import axios from 'axios';

export default function BankDepositSuccess() {
    const { textClass, getModeClass } = useTextClass();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [transactionDetails, setTransactionDetails] = useState(null);

    useEffect(() => {
        const processPayment = async () => {
            try {
                // Get all query parameters from the URL
                const queryParams = new URLSearchParams(location.search);
                const paymentData = {};

                // Convert query parameters to an object
                for (const [key, value] of queryParams.entries()) {
                    paymentData[key] = value;
                }

                // Only process if we have transaction data
                if (paymentData.TransactionId && paymentData.Status) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}/bank-deposit/process-success`,
                        paymentData,
                        { withCredentials: true }
                    );

                    setTransactionDetails(response.data.transaction);
                }
            } catch (err) {
                console.error('Error processing payment success:', err);
                setError('Failed to process payment. Please contact support.');
            } finally {
                setLoading(false);
            }
        };

        processPayment();
    }, [location]);

    if (loading) {
        return (
            <div className='page'>
                <div className="container my-5 text-center">
                    <Spinner animation="border" role="status" variant="primary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p className={textClass + " mt-3"}>Processing your payment...</p>
                </div>
            </div>
        );
    }

    return (
        <div className='page'>
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <Card className={getModeClass}>
                            <Card.Body className="text-center p-5">
                                <FaCheckCircle className="text-success" size={50} />
                                <h2 className={`${textClass} mt-4`}>Deposit Successful!</h2>

                                {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

                                {transactionDetails && (
                                    <div className="mt-3">
                                        <p className={`${textClass}`}>
                                            Your payment of <strong>R{parseFloat(transactionDetails.amount).toFixed(2)}</strong> has been processed successfully.
                                        </p>
                                        <p className={`${textClass} small`}>
                                            Transaction Reference: {transactionDetails.transactionReference}
                                        </p>
                                    </div>
                                )}

                                {!transactionDetails && !error && (
                                    <p className={`${textClass} mt-3`}>
                                        Your bank deposit has been successfully processed. The funds will be reflected in your account shortly.
                                    </p>
                                )}

                                <div className="mt-4">
                                    <Link to="/client/dashboard" className={`btn btn-success text-white me-3`}>
                                        Go to Dashboard
                                    </Link>
                                    <Link to="/client/history/transactions" className={`btn btn-warning`}>
                                        View Transactions
                                    </Link>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}