import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatCrypto, formatNumber } from '../../utils/helper';
import AmountPlaceholder from '../Placeholders/AmountPlaceholder';
import { useTextClass } from '../../hooks/useTextClass';
import useFetchWalletBalance from '../../hooks/useFetchWalletBalance';
import axios from 'axios';

export default function TradeQuoteSellModal({ show, onHide, coin1, coin2, coin1Amount, coin2Amount, rate, gasFees }) {
    const isLogged = useSelector((state) => state.user?.isLogged);
    const { textClass, getModeClass } = useTextClass();
    const [walletBalance, setWalletBalance] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Use the custom hook to fetch crypto wallet balance
    const { walletBalance: fetchedWalletBalance } = useFetchWalletBalance(coin1?.testnetShortCode);

    useEffect(() => {
        if (isLogged && fetchedWalletBalance !== null) {
            setWalletBalance(fetchedWalletBalance);
        }
    }, [isLogged, fetchedWalletBalance]);

    const handleTrade = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(null);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/transaction/sell`, {
                coinFrom: coin1.testnetShortCode,
                coinAmount: coin1Amount,
                zarAmount: coin2Amount
            }, { withCredentials: true });

            if (response.data) {
                setSuccessMessage("Your sell request has been submitted. You will receive an email confirmation shortly.");
                setTimeout(() => {
                    setSuccessMessage(null);
                    onHide();
                }, 3000);
            }
        } catch (error) {
            console.error('Error selling:', error);
            setErrorMessage(error.response?.data?.error || 'Failed to process sell request');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered className={getModeClass}>
            <Modal.Header closeButton>
                <Modal.Title className={`${textClass} text-center fw-bold`}>Current Quote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && (
                    <div className="text-center mb-3">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
                {successMessage && (
                    <div className="alert alert-success" role="alert">
                        {successMessage}
                    </div>
                )}
                {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>
                )}
                <div className="row">
                    <div className="col">
                        <h3 className={textClass}>Sell {coin1Amount} {coin1?.shortCode} for {formatNumber(coin2Amount)} {coin2?.shortCode}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong className={textClass}>{coin1?.shortCode} Rate:</strong>
                    </div>
                    <div className="col">
                        <p className={textClass}>{formatNumber(parseFloat(rate))} {coin2?.shortCode}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong className={textClass}>You Pay:</strong>
                    </div>
                    <div className="col">
                        <p className={textClass}>{formatCrypto(parseFloat(coin1Amount))} {coin1?.shortCode}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong className={textClass}>Network Fees (Included):</strong>
                    </div>
                    <div className="col">
                        <p className={textClass}>{formatCrypto(parseFloat(gasFees))} {coin2?.shortCode}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong className={textClass}>You Receive:</strong>
                    </div>
                    <div className="col">
                        <p className={textClass}>{formatNumber(parseFloat(coin2Amount))} {coin2?.shortCode}</p>
                    </div>
                </div>
                {isLogged && (
                    <p className={textClass}>
                        <strong>Available Balance:</strong>{' '}
                        <span className='fw-bold'>
                            {walletBalance !== null ? (
                                Number(walletBalance) >= Number(coin1Amount) ? (
                                    <span className="text-success">
                                        {`${formatCrypto(Number(walletBalance))} ${coin1?.shortCode}`}
                                    </span>
                                ) : (
                                    <span className="text-danger">
                                        {formatCrypto(Number(walletBalance))} {coin1?.shortCode} (Not enough funds)
                                    </span>
                                )
                            ) : (
                                <AmountPlaceholder />
                            )}
                        </span>
                    </p>
                )}
                {!isLogged && (
                    <p className={textClass}>
                        This quote is indicative only. Please <Link className='text-info fw-medium text-decoration-underline' to="/signin">Sign in</Link> or <Link className='text-info fw-medium text-decoration-underline' to="/signup">Sign up</Link> to proceed with the trade.
                    </p>
                )}
            </Modal.Body>
            <Modal.Footer>
                {!isLogged ? (
                    <Button as={Link} to="/signup" variant="primary">
                        Get Started
                    </Button>
                ) : (
                    <>
                        <Button variant="secondary" onClick={onHide}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={walletBalance < coin1Amount || isLoading}
                            onClick={handleTrade}
                        >
                            {isLoading ? 'Processing...' : 'Confirm Trade'}
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
} 