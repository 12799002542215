import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import moment from "moment";
import TablePlaceholder from "../Placeholders/TablePlaceholder";
import { useSelector } from "react-redux";
import { Pagination, Button, ButtonGroup, Form } from "react-bootstrap";
import { FaFileExcel, FaFileCsv, FaFilePdf } from "react-icons/fa";
import {
    fetchTransactionsWithFilters,
    fetchWalletBalance
} from "../../utils/apiUtils";
import {
    formatTransactionData,
    exportTransactionsToExcel,
    exportTransactionsToCsv,
    exportTransactionsToPdf,
    getTransactionTypeStyle,
    getTransactionStatusStyle
} from "../../utils/helper";
import { useTextClass } from "../../hooks/useTextClass";

// Lazy load the components
const DataTable = React.lazy(() => import("react-data-table-component"));

export default function TransactionsHistoryTable({ userId, showFilters = false }) {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { textClass } = useTextClass();
    const userDetails = useSelector((state) => state.user.userDetails);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        total: 0,
        pages: 1
    });
    const [filters, setFilters] = useState({
        coinType: '',
        status: '',
        transactionType: '',
        startDate: '',
        endDate: ''
    });
    // Add state for export all toggle
    const [exportAll, setExportAll] = useState(false);
    const [allTransactions, setAllTransactions] = useState([]);
    const [loadingAllData, setLoadingAllData] = useState(false);

    // Columns for the DataTable
    const columns = [
        {
            name: "Coin Type",
            selector: (row) => row.coinType,
            sortable: true,
        },
        {
            name: "Amount",
            selector: (row) => (
                <span style={getTransactionTypeStyle(row.transactionType)}>
                    {parseFloat(row.amount).toFixed(8)}
                </span>
            ),
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => (
                <span style={getTransactionTypeStyle(row.transactionType)} className="text-capitalize">
                    {row.transactionType}
                </span>
            ),
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => (
                <span style={getTransactionStatusStyle(row.status)} className="text-uppercase">
                    {row.status}
                </span>
            ),
            sortable: true,
        },
        {
            name: "Date",
            selector: (row) => moment(row.createdAt).format("YYYY-MM-DD hh:mm A"),
            sortable: true,
            grow: 2,
        },
    ];

    // Function to fetch all transactions for export
    const fetchAllTransactionsForExport = async () => {
        if (!exportAll) return transactions;

        if (allTransactions.length > 0) return allTransactions;

        setLoadingAllData(true);
        try {
            // Build query parameters without pagination
            const params = { ...filters, limit: 1000 };

            // Only include userId in params if it's provided (admin view)
            if (userId) {
                params.userId = userId;
            }

            const { transactions: allData } = await fetchTransactionsWithFilters(params);
            setAllTransactions(allData);
            return allData;
        } catch (error) {
            console.error("Error fetching all transactions for export:", error);
            return transactions; // Fallback to current page data
        } finally {
            setLoadingAllData(false);
        }
    };

    // Export functions
    const handleExportToExcel = async () => {
        const dataToExport = exportAll ? await fetchAllTransactionsForExport() : transactions;
        const formattedData = formatTransactionData(dataToExport);
        exportTransactionsToExcel(formattedData, 'My_Transactions_Export');
    };

    const handleExportToCsv = async () => {
        const dataToExport = exportAll ? await fetchAllTransactionsForExport() : transactions;
        const formattedData = formatTransactionData(dataToExport);
        exportTransactionsToCsv(formattedData, 'My_Transactions_Export');
    };

    const handleExportToPdf = async () => {
        const dataToExport = exportAll ? await fetchAllTransactionsForExport() : transactions;
        const formattedData = formatTransactionData(dataToExport);
        exportTransactionsToPdf(formattedData, 'My_Transactions_Export', 'My Transaction History');
    };

    // Handle filter change
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Clear filters
    const clearFilters = () => {
        setFilters({
            coinType: '',
            status: '',
            transactionType: '',
            startDate: '',
            endDate: ''
        });
    };

    // Fetch transactions from API with pagination and filters
    const fetchTransactions = async () => {
        try {
            setLoading(true);
            setError("");

            // Build query parameters
            const params = {
                page: pagination.page,
                limit: pagination.limit,
                ...filters
            };

            // Only include userId in params if it's provided (admin view)
            if (userId) {
                params.userId = userId;
            }

            const { transactions: fetchedTransactions, pagination: paginationData } =
                await fetchTransactionsWithFilters(params);

            setTransactions(fetchedTransactions);
            setPagination(paginationData);
        } catch (error) {
            console.error("Error fetching transactions:", error);
            setError("Error fetching transactions. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    // Handle page change
    const handlePageChange = (page) => {
        setPagination(prev => ({
            ...prev,
            page
        }));
    };

    // Handle rows per page change
    const handlePerRowsChange = (newLimit, page) => {
        setPagination(prev => ({
            ...prev,
            page,
            limit: newLimit
        }));
    };

    // Custom pagination component
    const CustomPagination = () => {
        const pages = [];
        const maxPagesToShow = 5;

        let startPage = Math.max(1, pagination.page - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(pagination.pages, startPage + maxPagesToShow - 1);

        if (endPage - startPage + 1 < maxPagesToShow) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        // Add first page
        if (startPage > 1) {
            pages.push(
                <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                pages.push(<Pagination.Ellipsis key="ellipsis-1" />);
            }
        }

        // Add pages in the middle
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <Pagination.Item
                    key={i}
                    active={i === pagination.page}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        // Add last page
        if (endPage < pagination.pages) {
            if (endPage < pagination.pages - 1) {
                pages.push(<Pagination.Ellipsis key="ellipsis-2" />);
            }
            pages.push(
                <Pagination.Item
                    key={pagination.pages}
                    onClick={() => handlePageChange(pagination.pages)}
                >
                    {pagination.pages}
                </Pagination.Item>
            );
        }

        return (
            <Pagination className="justify-content-center mt-3">
                <Pagination.Prev
                    onClick={() => handlePageChange(pagination.page - 1)}
                    disabled={pagination.page === 1}
                />
                {pages}
                <Pagination.Next
                    onClick={() => handlePageChange(pagination.page + 1)}
                    disabled={pagination.page === pagination.pages}
                />
            </Pagination>
        );
    };

    useEffect(() => {
        // For admin view (with userId), check if userId exists
        // For user view (without userId), check if user has fireblocksVaultId
        if (userId || (userDetails?.fireblocksVaultId)) {
            fetchTransactions();
            // Reset allTransactions when filters change
            setAllTransactions([]);
        }
    }, [userId, userDetails, pagination.page, pagination.limit, filters]);

    return (
        <div>
            {error && <div className="alert alert-danger">{error}</div>}

            {showFilters && (
                <div className="mb-3">
                    <div className="row mb-3">
                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label className={textClass}>Coin Type</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="coinType"
                                    value={filters.coinType}
                                    onChange={handleFilterChange}
                                    placeholder="BTC, ETH, etc."
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label className={textClass}>Status</Form.Label>
                                <Form.Select
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All Statuses</option>
                                    <option value="completed">Completed</option>
                                    <option value="pending">Pending</option>
                                    <option value="failed">Failed</option>
                                    <option value="cancelled">Cancelled</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label className={textClass}>Transaction Type</Form.Label>
                                <Form.Select
                                    name="transactionType"
                                    value={filters.transactionType}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All Types</option>
                                    <option value="deposit">Deposit</option>
                                    <option value="withdrawal">Withdrawal</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group>
                                <Form.Label className={textClass}>Date Range</Form.Label>
                                <div className="d-flex">
                                    <Form.Control
                                        type="date"
                                        name="startDate"
                                        value={filters.startDate}
                                        onChange={handleFilterChange}
                                        className="me-2"
                                        placeholder="Start Date"
                                    />
                                    <Form.Control
                                        type="date"
                                        name="endDate"
                                        value={filters.endDate}
                                        onChange={handleFilterChange}
                                        placeholder="End Date"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex justify-content-end  mt-4">
                                <Button variant="secondary" size="sm" onClick={clearFilters} className="me-2 mt-3">
                                    Clear Filters
                                </Button>
                                <Button variant="primary" size="sm" className="mt-3" onClick={fetchTransactions}>
                                    Apply Filters
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showFilters && (
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <Form.Check
                        type="switch"
                        id="export-all-switch"
                        className={textClass}
                        label={`Export ${exportAll ? 'all' : 'current page'} records (${exportAll ? pagination.total : `${transactions.length} out of ${pagination.total}`})`}
                        checked={exportAll}
                        onChange={() => setExportAll(!exportAll)}
                    />

                    <ButtonGroup>
                        <Button
                            variant="success"
                            size="sm"
                            onClick={handleExportToExcel}
                            disabled={loadingAllData || transactions.length === 0}
                        >
                            <FaFileExcel className="me-1" /> Excel
                        </Button>

                        <Button
                            variant="primary"
                            size="sm"
                            onClick={handleExportToCsv}
                            disabled={loadingAllData || transactions.length === 0}
                        >
                            <FaFileCsv className="me-1" /> CSV
                        </Button>

                        <Button
                            variant="danger"
                            size="sm"
                            onClick={handleExportToPdf}
                            disabled={loadingAllData || transactions.length === 0}
                        >
                            <FaFilePdf className="me-1" /> PDF
                        </Button>
                    </ButtonGroup>
                </div>
            )}

            {loadingAllData && exportAll && (
                <div className="alert alert-info">
                    Loading all records for export... This may take a moment.
                </div>
            )}

            <Suspense fallback={<TablePlaceholder />}>
                <DataTable
                    columns={columns}
                    data={transactions}
                    progressPending={loading}
                    pagination={true}
                    paginationTotalRows={pagination.total}
                    paginationPerPage={pagination.limit}
                    paginationDefaultPage={pagination.page}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationComponent={CustomPagination}
                    persistTableHead
                    responsive
                    highlightOnHover
                    striped
                    noDataComponent={
                        <div className="p-4 text-center">
                            No transactions found. Try adjusting your filters.
                        </div>
                    }
                />
            </Suspense>
        </div>
    );
}