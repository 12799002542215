import React, { useEffect, Suspense } from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from './routes';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App({ skipRouter = false }) {
  const mode = useSelector((state) => state.user.mode);

  useEffect(() => {
    document.body.style.backgroundColor = mode === 'dark' ? '#001a41' : '#ffffff';
  }, [mode]);

  const content = (
    <div className="App">
      <Suspense fallback={<Spinner animation="border" variant="dark" />}>
        <AppRoutes />
      </Suspense>
    </div>
  );

  if (skipRouter) {
    return <div data-testid="app">{content}</div>;
  }

  return (
    <div data-testid="app">
      <BrowserRouter>
        {content}
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;