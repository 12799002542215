import React from "react";
import Placeholder from "react-bootstrap/Placeholder"; // Import Bootstrap Placeholder

export default function TablePlaceholder({ columns = 5, rows = 10 }) {
    return (
        <div className="table-responsive">
            <table className="table table-borderless">
                <thead>
                    <tr>
                        {Array.from({ length: columns }).map((_, index) => (
                            <th key={index}>
                                <Placeholder as="span" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: rows }).map((_, rowIndex) => (
                        <tr key={rowIndex}>
                            {Array.from({ length: columns }).map((_, colIndex) => (
                                <td key={colIndex}>
                                    <Placeholder as="span" animation="glow">
                                        <Placeholder xs={10} />
                                    </Placeholder>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
