import React, { useState } from 'react';
import { createClient } from 'contentful';
import * as XLSX from 'xlsx';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from 'moment';
// Import toast from react-toastify
import { toast } from 'react-toastify';

export const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const formatNumber = (number) => {
    if (!number) return '0';
    return number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const formatCrypto = (number) => {
    if (!number) return '0.000000'; // Default to 6 decimal places if number is falsy
    return number.toLocaleString('en-US', {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
    });
};

// New export utility functions
export const exportTransactionsToExcel = (data, filename = 'Transactions_Export') => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { 'Transactions': ws }, SheetNames: ['Transactions'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename + fileExtension;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const exportTransactionsToCsv = (data, filename = 'Transactions_Export') => {
    // Extract headers from the first object
    const headers = Object.keys(data[0] || {});

    // Convert data to CSV rows
    const csvData = data.map(row =>
        headers.map(header => row[header]).join(",")
    );

    // Add headers to the beginning
    csvData.unshift(headers.join(","));

    // Convert to CSV string
    const csvContent = csvData.join("\n");

    // Create download link
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const exportTransactionsToPdf = (data, filename = 'Transactions_Export', title = 'Transaction History') => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add title
    doc.text(title, 14, 16);

    // Prepare table data
    const headers = Object.keys(data[0] || {});
    const tableData = data.map(row => headers.map(header => row[header]));

    // Use autoTable
    autoTable(doc, {
        head: [headers],
        body: tableData,
        startY: 20,
        styles: { fontSize: 8, cellPadding: 2 },
        headStyles: { fillColor: [66, 66, 66] }
    });

    // Save PDF
    doc.save(filename + ".pdf");
};

// Transaction data formatting utility
export const formatTransactionData = (transactions) => {
    return transactions.map(row => ({
        "Coin Type": row.coinType,
        "Amount": parseFloat(row.amount).toFixed(8),
        "Type": row.transactionType,
        "Status": row.status,
        "Date": moment(row.createdAt).format("YYYY-MM-DD hh:mm A")
    }));
};

// Style utilities for transactions
export const getTransactionTypeStyle = (transactionType) => {
    return transactionType === "deposit" ? { color: "green" } : { color: "red" };
};

export const getTransactionStatusStyle = (status) => {
    switch (status.toUpperCase()) {
        case "COMPLETED":
            return { color: "green", fontWeight: "bold" };
        case "PENDING":
        case "BROADCASTING":
        case "CONFIRMING":
            return { color: "orange", fontWeight: "bold" };
        case "FAILED":
        case "CANCELLED":
        case "BLOCKED":
        case "REJECTED":
            return { color: "red", fontWeight: "bold" };
        default:
            return { color: "black" };
    }
};

// Form handling helpers
export const useForm = (initialValues) => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const resetForm = () => {
        setValues(initialValues);
        setErrors({});
    };

    return { values, handleChange, errors, setErrors, resetForm, setValues };
};


// Toast message helper
export const showToast = (message, type = 'success', duration = 3000) => {
    if (type === 'success') {
        toast.success(message, { autoClose: duration });
    } else if (type === 'error') {
        toast.error(message, { autoClose: duration });
    } else if (type === 'info') {
        toast.info(message, { autoClose: duration });
    } else if (type === 'warning') {
        toast.warning(message, { autoClose: duration });
    } else {
        toast(message, { autoClose: duration });
    }
};

// Date formatting helpers
export const formatDate = (date, format = 'YYYY-MM-DD') => {
    return moment(date).format(format);
};

export const formatDateTime = (date, format = 'YYYY-MM-DD hh:mm A') => {
    return moment(date).format(format);
};

// Image handling helper
export const getImageUrl = (imageUrl, fallbackUrl = '/assets/images/avatar.png') => {
    return imageUrl || fallbackUrl;
};

// Validation helpers
export const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};

export const validatePassword = (password) => {
    // At least 8 characters, one uppercase, one lowercase, one number, one special character
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
};

// Status badge helper
export const getStatusBadgeClass = (status) => {
    status = status.toLowerCase();
    if (['completed', 'approved', 'active'].includes(status)) {
        return 'bg-success';
    } else if (['pending', 'processing', 'in progress'].includes(status)) {
        return 'bg-warning text-dark';
    } else if (['failed', 'rejected', 'cancelled', 'blocked'].includes(status)) {
        return 'bg-danger';
    }
    return 'bg-secondary';
};

