import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import axios from 'axios';
import { CgArrowsExchangeAltV } from "react-icons/cg";
import TradeQuoteSwapModal from '../Modals/TradeQuoteSwapModal'; // You can create this modal similar to the Buy modal
import { formatCrypto } from '../../utils/helper';
import { setSelectedCryptoPair } from '../../redux/coinSlice';
import { Dropdown } from 'react-bootstrap';

export default function SwapForm() {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [gasFees, setGasFees] = useState(0);
    const [tradeData, setTradeData] = useState({
        coin1: 0,
        coin2: 0,
        rate: 0, // Start with 0 and update it with the adjusted rate
    });

    const { coin1, coin2, rate } = tradeData;
    const [coin1DropdownOpen, setCoin1DropdownOpen] = useState(false);
    const [coin2DropdownOpen, setCoin2DropdownOpen] = useState(false);

    const handleCoin1Toggle = (isOpen) => {
        setCoin1DropdownOpen(isOpen);
        if (isOpen) {
            setCoin2DropdownOpen(false); // Close coin2 dropdown if coin1 is open
        }
    };

    const handleCoin2Toggle = (isOpen) => {
        setCoin2DropdownOpen(isOpen);
        if (isOpen) {
            setCoin1DropdownOpen(false); // Close coin1 dropdown if coin2 is open
        }
    };

    const [isCalculating, setIsCalculating] = useState(false);

    const { tradePairs, selectedCryptoPair } = useSelector(state => state.coin);

    const [adjustedCoin1Price, setAdjustedCoin1Price] = useState(0);
    const [adjustedCoin2Price, setAdjustedCoin2Price] = useState(0);

    const fetchAdjustedPrice = async (symbol) => {
        try {
            const response = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=USDT`);
            return response.data['USDT'];
        } catch (error) {
            console.error('Error fetching prices:', error);
            return 0;
        }
    };

    // Fetch Gas Fees (Mock example: you might have an API for it)
    const fetchGasFees = async (symbol, amount) => {
        try {
            const payload = {
                assetId: symbol,
                amount: amount
            }
            // Mock API response for gas fee
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/transaction/get_estimatedFee`, payload, {
                withCredentials: true
            });
            return response.data?.estimatedFee.high?.networkFee; // assuming the gas fee is returned here
        } catch (error) {
            console.error('Error fetching gas fees:', error);
            return 0;
        }
    };

    const updatePrices = async () => {
        const coin1Price = await fetchAdjustedPrice(selectedCryptoPair?.coin1?.shortCode);
        const coin2Price = await fetchAdjustedPrice(selectedCryptoPair?.coin2?.shortCode);
        setAdjustedCoin1Price(coin1Price);
        setAdjustedCoin2Price(coin2Price);

        // Calculate the exchange rate
        const originalRate = coin1Price / coin2Price;

        // Adjust the rate by adding 1%
        const adjustedRate = originalRate - (originalRate * 0.01);

        // Update the state with the adjusted rate
        setTradeData(prevState => ({
            ...prevState,
            rate: adjustedRate.toFixed(6), // Store the adjusted rate with 6 decimal places
        }));
    };

    useEffect(() => {
        updatePrices();
    }, [selectedCryptoPair]);

    const debouncedCalculateCoin2Value = useMemo(() =>
        debounce(async (coin1Amount) => {
            if (!coin1Amount || !adjustedCoin1Price || !adjustedCoin2Price) return;
            setIsCalculating(true);
            try {
                // Fetch gas fees concurrently
                const [gasFeeForCoin1, gasFeeForCoin2] = await Promise.all([
                    fetchGasFees(selectedCryptoPair?.coin1?.testnetShortCode, coin1),
                    fetchGasFees(selectedCryptoPair?.coin2?.testnetShortCode, coin2),
                ]);
                // Calculate original coin2 amount and gas fees
                const coin2AmountOriginal = (coin1Amount * adjustedCoin1Price) / adjustedCoin2Price;
                const coin1GasFees = (gasFeeForCoin1 * adjustedCoin1Price) / adjustedCoin2Price;
                const coin2GasFees = (gasFeeForCoin2 * adjustedCoin2Price) / adjustedCoin2Price;
                const totalGasFees = coin1GasFees + coin2GasFees;
                // Update state
                setGasFees(totalGasFees.toFixed(4));
                const coin2Amount = coin2AmountOriginal - totalGasFees - (coin2AmountOriginal * 0.01); // Adding a small fee (example)
                setTradeData(prevState => ({
                    ...prevState,
                    coin2: coin2Amount.toFixed(6), // Keep the coin2 value as plain number
                }));
            } catch (error) {
                console.error('Error calculating coin2 value:', error);
            } finally {
                setIsCalculating(false); // Reset calculating state
            }
        }, 1000),
        [adjustedCoin1Price, adjustedCoin2Price, selectedCryptoPair]);

    useEffect(() => {
        if (coin1) {
            debouncedCalculateCoin2Value(parseFloat(coin1));
        } else {
            setTradeData(prevState => ({
                ...prevState,
                coin2: ''
            }));
        }
        return () => {
            debouncedCalculateCoin2Value.cancel();
        };
    }, [coin1, debouncedCalculateCoin2Value]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        const numericValue = value.replace(/,/g, '');
        setTradeData({
            ...tradeData,
            [id]: numericValue
        });
    };

    const toggleModal = () => setShowModal(!showModal);

    // Swap the values of coin1 and coin2
    const handleSwap = () => {
        // Find the pair where coin1 and coin2 match
        const selectedPair = tradePairs.find(pair => {
            return (
                (pair.coin1.shortCode === selectedCryptoPair.coin2.shortCode && pair.coin2.shortCode === selectedCryptoPair.coin1.shortCode)
            );
        });
        if (selectedPair) {
            // Dispatch the action to set the selected crypto pair
            dispatch(setSelectedCryptoPair(selectedPair));
        }
    };
    const handleCoinSelect = (coin, pair) => {
        dispatch(setSelectedCryptoPair(pair));
    };
    return (
        <form>
            <div className="text-center mx-2">
                <h5 className="font-bold">{`Exchange ${selectedCryptoPair?.coin1.shortCode} with ${selectedCryptoPair?.coin2.shortCode}`}</h5>
            </div>
            <div className="d-flex align-items-center mb-1">
                <div className="flex-grow-1">
                    <label htmlFor="coin1">Exchange Amount</label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id="coin1"
                            name="coin1"
                            placeholder="0"
                            value={coin1}
                            onChange={handleInputChange}
                            step="any"
                        />
                        <Dropdown
                            className="input-group-text"
                            show={coin1DropdownOpen}
                            onToggle={handleCoin1Toggle}
                        >
                            <Dropdown.Toggle
                                variant="basic"
                                id="coin-dropdown"
                                className="d-flex align-items-center cursor-pointer"
                            >
                                <img
                                    alt={selectedCryptoPair?.coin1?.name}
                                    className="img-fluid coin-symbol me-1"
                                    src={selectedCryptoPair?.coin1?.icon}
                                />
                                {selectedCryptoPair?.coin1?.shortCode}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {tradePairs
                                    .filter(pair =>
                                        pair.coin2.shortCode === selectedCryptoPair.coin2.shortCode &&
                                        pair.coin1.shortCode !== selectedCryptoPair.coin1.shortCode &&
                                        pair.pairType === "crypto")
                                    .map(pair => (
                                        <Dropdown.Item
                                            as="button"
                                            key={pair.coin1.shortCode}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleCoinSelect('coin1', pair);
                                                setCoin1DropdownOpen(false); // Close the dropdown after selection
                                            }}
                                        >
                                            <img
                                                alt={pair.coin1.name}
                                                className="img-fluid coin-symbol me-1"
                                                src={pair.coin1.icon}
                                            />
                                            {pair.coin1.shortCode}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <button type="button" className="btn btn-light" onClick={handleSwap}>
                    <CgArrowsExchangeAltV size={24} />
                </button>
            </div>
            <div className="d-flex align-items-center mb-1">
                <div className="flex-grow-1">
                    <label htmlFor="coin2">Receive</label>
                    <div className="input-group placeholder-glow">
                        <input
                            type="text"
                            className={`form-control ${isCalculating ? "placeholder col-12" : ""}`}
                            id="coin2"
                            name="coin2"
                            placeholder="0"
                            value={coin2}
                            step="any"
                            readOnly
                        />
                        <Dropdown
                            className="input-group-text"
                            show={coin2DropdownOpen}
                            onToggle={handleCoin2Toggle}
                        >
                            <Dropdown.Toggle
                                variant="basic"
                                id="coin-dropdown"
                                className="d-flex align-items-center cursor-pointer"
                            >
                                <img
                                    alt={selectedCryptoPair?.coin2?.name}
                                    className="img-fluid coin-symbol me-1"
                                    src={selectedCryptoPair?.coin2?.icon}
                                />
                                {selectedCryptoPair?.coin2?.shortCode}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {tradePairs
                                    .filter(pair =>
                                        pair.coin1.shortCode === selectedCryptoPair.coin1.shortCode &&
                                        pair.coin2.shortCode !== selectedCryptoPair.coin2.shortCode &&
                                        pair.pairType === "crypto"
                                    )
                                    .map(pair => (
                                        <Dropdown.Item
                                            as="button"
                                            key={pair.coin2.shortCode}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleCoinSelect('coin2', pair);
                                                setCoin2DropdownOpen(false); // Close the dropdown after selection
                                            }}
                                        >
                                            <img
                                                alt={pair.coin2.name}
                                                className="img-fluid coin-symbol me-1"
                                                src={pair.coin2.icon}
                                            />
                                            {pair.coin2.shortCode}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between mb-2">
                <div className="fw-lighter">Network Fees (Included)</div>
                <div>
                    {isCalculating &&
                        <span className="placeholder-glow">
                            <span className="placeholder col-3"></span>
                        </span>
                    }
                    {gasFees > 0 && `${formatCrypto(parseFloat(gasFees))}${selectedCryptoPair?.coin2.shortCode}`}
                </div>
            </div>
            <div>
                <button
                    type="button"
                    onClick={toggleModal}
                    className={`btn btn-info w-100 mb-2 ${isCalculating <= 0 ? "placeholder-wave" : ""}`}
                    disabled={coin1 <= 0 || !coin2 || !gasFees}
                >
                    {isCalculating
                        ? <span className="placeholder col-6"></span>
                        : "Get Exchange Quote"}
                </button>
            </div>
            <TradeQuoteSwapModal
                show={showModal}
                onHide={toggleModal}
                coin1={selectedCryptoPair?.coin1}
                coin2={selectedCryptoPair?.coin2}
                coin1Amount={coin1}
                coin2Amount={coin2}
                gasFees={gasFees}
                rate={rate}
            />
        </form>
    );
}