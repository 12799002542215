import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import TransactionsHistoryTable from '../../components/Tables/TransactionsHistoryTable';
import { useSelector } from 'react-redux';
import { useTextClass } from '../../hooks/useTextClass';
import { FiArrowLeft } from 'react-icons/fi';

export default function UserWallet() {
    const { userId } = useParams();
    const { textClass, getModeClass } = useTextClass();
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(0);
    const { coins } = useSelector(state => state.coin);
    const [topUpAmount, setTopUpAmount] = useState('');
    const [bankReference, setBankReference] = useState('');
    const [loading, setLoading] = useState(false);
    const [walletBalances, setWalletBalances] = useState({});
    const navigate = useNavigate();
    const [transactionRefreshKey, setTransactionRefreshKey] = useState(0);

    // Fetch user details
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}`,
                    { withCredentials: true }
                );
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user:', error);
                Swal.fire('Error', 'Failed to fetch user details', 'error');
            }
        };
        fetchUserDetails();
    }, [userId]);

    // Fetch user's balance
    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/wallet/admin/user-balance`,
                    {
                        withCredentials: true,
                        params: {
                            userId,
                            baseCoin: 'ZAR'
                        }
                    }
                );
                setBalance(response.data.totalBalanceInBaseCoin);
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        };
        if (user?.fireblocksVaultId) {
            fetchBalance();
        }
    }, [user, userId]);

    // Add new effect to fetch wallet balances
    useEffect(() => {
        const fetchWalletBalances = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/wallet/admin/user-wallets`,
                    {
                        withCredentials: true,
                        params: { userId }
                    }
                );
                const balancesMap = response.data.wallets.reduce((acc, wallet) => {
                    acc[wallet.coinType] = wallet;
                    return acc;
                }, {});
                setWalletBalances(balancesMap);
            } catch (error) {
                console.error('Error fetching wallet balances:', error);
            }
        };

        if (user?.fireblocksVaultId) {
            fetchWalletBalances();
        }
    }, [user, userId]);

    const handleTopUp = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/wallet/admin/topup-zar`,
                {
                    userId,
                    amount: topUpAmount,
                    bankReference
                },
                { withCredentials: true }
            );

            if (response.data.success) {
                Swal.fire('Success', 'Top-up completed successfully', 'success');
                setTopUpAmount('');
                setBankReference('');

                // Refresh all balances
                try {
                    // Refresh total balance
                    const balanceResponse = await axios.get(
                        `${process.env.REACT_APP_API_URL}/wallet/admin/user-balance`,
                        {
                            withCredentials: true,
                            params: {
                                userId,
                                baseCoin: 'ZAR'
                            }
                        }
                    );
                    setBalance(balanceResponse.data.totalBalanceInBaseCoin);

                    // Refresh individual wallet balances
                    const walletsResponse = await axios.get(
                        `${process.env.REACT_APP_API_URL}/wallet/admin/user-wallets`,
                        {
                            withCredentials: true,
                            params: { userId }
                        }
                    );
                    const balancesMap = walletsResponse.data.wallets.reduce((acc, wallet) => {
                        acc[wallet.coinType] = wallet;
                        return acc;
                    }, {});
                    setWalletBalances(balancesMap);

                    // Force transaction table to refresh
                    setTransactionRefreshKey(prev => prev + 1);
                } catch (error) {
                    console.error('Error refreshing balances:', error);
                    Swal.fire('Warning', 'Top-up successful but failed to refresh balances', 'warning');
                }
            }
        } catch (error) {
            console.error('Error processing top-up:', error);
            Swal.fire('Error', error.response?.data?.error || 'Failed to process top-up', 'error');
        } finally {
            setLoading(false);
        }
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className='page'>
            <div className="container my-4">
                <div className="d-flex align-items-center mb-4">
                    <button
                        className="btn btn-link text-decoration-none p-0 me-3"
                        onClick={() => navigate('/admin/users')}
                    >
                        <FiArrowLeft size={24} className={textClass} />
                    </button>
                    <h2 className={`${textClass} m-0`}>Wallet Management - {user.FirstName} {user.LastName}</h2>
                </div>

                <Row className="mt-4">
                    <Col md={6}>
                        <Card className={getModeClass}>
                            <Card.Header className={textClass}>
                                <strong>Total Balance</strong>
                            </Card.Header>
                            <Card.Body>
                                <h3 className={textClass}>ZAR {parseFloat(balance).toFixed(2)}</h3>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={6}>
                        <Card className={getModeClass}>
                            <Card.Header className={textClass}>
                                <strong>Top Up ZAR Balance</strong>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleTopUp}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={textClass}>Amount (ZAR)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={topUpAmount}
                                            onChange={(e) => setTopUpAmount(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label className={textClass}>Bank Reference</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={bankReference}
                                            onChange={(e) => setBankReference(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        disabled={loading}
                                    >
                                        {loading ? 'Processing...' : 'Top Up'}
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Card className={`${getModeClass} mt-4`}>
                    <Card.Header className={textClass}>
                        <strong>Crypto Wallets</strong>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {coins
                                .filter(coin => coin.coinType === 'crypto' && coin.isActive)
                                .map(coin => {
                                    const walletBalance = walletBalances[coin.shortCode] || { balance: '0', valueInZAR: '0' };
                                    return (
                                        <Col md={6} key={coin.shortCode} className="mb-3">
                                            <Card>
                                                <Card.Header>
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            src={coin.icon}
                                                            alt={coin.name}
                                                            style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                                        />
                                                        <strong>{coin.name} ({coin.shortCode})</strong>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                    <div>
                                                        <strong>Balance:</strong> {parseFloat(walletBalance.balance).toFixed(8)} {coin.shortCode}
                                                        <br />
                                                        <strong>Value:</strong> ZAR {walletBalance.valueInZAR}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </Card.Body>
                </Card>

                {/* Add Fiat Wallets Card */}
                <Card className={`${getModeClass} mt-4`}>
                    <Card.Header className={textClass}>
                        <strong>Fiat Wallets</strong>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {Object.entries(walletBalances)
                                .filter(([code, wallet]) => !wallet.isFireblocks)
                                .map(([code, wallet]) => (
                                    <Col md={6} key={code}>
                                        <Card>
                                            <Card.Header>
                                                <strong>{code}</strong>
                                            </Card.Header>
                                            <Card.Body>
                                                <div>
                                                    <strong>Balance:</strong> {parseFloat(wallet.balance).toFixed(2)} {code}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    </Card.Body>
                </Card>

                <Card className={`${getModeClass} mt-4`}>
                    <Card.Header className={textClass}>
                        <strong>Transaction History</strong>
                    </Card.Header>
                    <Card.Body>
                        <TransactionsHistoryTable
                            userId={userId}
                            key={transactionRefreshKey}
                        />
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
} 