import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';  // Import useSelector to access Redux state
import { fetchFiatWalletBalance } from '../utils/apiUtils';

const useFetchFiatWalletBalance = (coinType) => {
    const isLogged = useSelector((state) => state.user?.isLogged);  // Get login status from Redux state
    const [walletBalance, setWalletBalance] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (!isLogged) {
            setWalletBalance(null);  // Reset wallet balance if the user is not logged in
            return;
        }

        const getCoins = async () => {
            try {
                const balance = await fetchFiatWalletBalance(coinType);
                setWalletBalance(balance);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };

        getCoins();
    }, [coinType, isLogged]);  // Re-run when coinType or isLogged changes

    return { walletBalance, errorMessage };
};

export default useFetchFiatWalletBalance;
