import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Suspense } from 'react';
import TablePlaceholder from '../../../components/Placeholders/TablePlaceholder';
import { useTextClass } from '../../../hooks/useTextClass';

// Lazy load DataTable component
const DataTable = React.lazy(() => import('react-data-table-component'));

export default function BankWithdrawRequests() {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [bankReference, setBankReference] = useState('');
    const [rejectionReason, setRejectionReason] = useState('');
    const { textClass } = useTextClass();

    const fetchRequests = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/withdrawals/bank-withdrawal-requests`,
                { withCredentials: true }
            );
            setRequests(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching withdrawal requests:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRequests();
    }, []);

    const handleViewDetails = (request) => {
        setSelectedRequest(request);
        setShowDetailsModal(true);
    };

    const handleApprove = async (request) => {
        const { value: reference } = await Swal.fire({
            title: 'Enter Bank Reference',
            input: 'text',
            inputLabel: 'Bank Reference/Transaction ID',
            inputPlaceholder: 'Enter the bank reference',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Please enter a bank reference';
                }
            }
        });

        if (reference) {
            try {
                await axios.put(
                    `${process.env.REACT_APP_API_URL}/withdrawals/bank-withdrawal-requests/${request._id}`,
                    {
                        status: 'approved',
                        bankReference: reference
                    },
                    { withCredentials: true }
                );
                Swal.fire('Success', 'Withdrawal request approved successfully', 'success');
                fetchRequests();
            } catch (error) {
                console.error('Error approving withdrawal:', error);
                Swal.fire('Error', 'Failed to approve withdrawal request', 'error');
            }
        }
    };

    const handleReject = async (request) => {
        const { value: reason } = await Swal.fire({
            title: 'Rejection Reason',
            input: 'textarea',
            inputLabel: 'Please provide a reason for rejection',
            inputPlaceholder: 'Enter rejection reason...',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Please enter a reason for rejection';
                }
            }
        });

        if (reason) {
            try {
                await axios.put(
                    `${process.env.REACT_APP_API_URL}/withdrawals/bank-withdrawal-requests/${request._id}`,
                    {
                        status: 'rejected',
                        rejectionReason: reason
                    },
                    { withCredentials: true }
                );
                Swal.fire('Success', 'Withdrawal request rejected successfully', 'success');
                fetchRequests();
            } catch (error) {
                console.error('Error rejecting withdrawal:', error);
                Swal.fire('Error', 'Failed to reject withdrawal request', 'error');
            }
        }
    };

    const columns = [
        {
            name: 'User',
            selector: row => `${row.userId.FirstName} ${row.userId.LastName}`,
            sortable: true,
        },
        {
            name: 'Amount (ZAR)',
            selector: row => row.amount.toFixed(2),
            sortable: true,
        },
        {
            name: 'Bank',
            selector: row => row.bankAccountId.bankName,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                <span className={`text-capitalize badge bg-${row.status === 'pending' ? 'warning' : row.status === 'approved' ? 'success' : 'danger'}`}>
                    {row.status}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Requested At',
            selector: row => new Date(row.createdAt).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="d-flex gap-2">
                    <Button
                        size="sm"
                        variant="info"
                        onClick={() => handleViewDetails(row)}
                    >
                        View
                    </Button>
                    {row.status === 'pending' && (
                        <>
                            <Button
                                size="sm"
                                variant="success"
                                onClick={() => handleApprove(row)}
                            >
                                Approve
                            </Button>
                            <Button
                                size="sm"
                                variant="danger"
                                onClick={() => handleReject(row)}
                            >
                                Reject
                            </Button>
                        </>
                    )}
                </div>
            ),
        },
    ];
    console.log(requests, "requests")
    return (
        <div className="page">
            <div className="container my-4">
                <h2 className={textClass}>Bank Withdrawal Requests</h2>
                <Suspense fallback={<TablePlaceholder />}>
                    <DataTable
                        columns={columns}
                        data={requests}
                        pagination
                        highlightOnHover
                        striped
                        progressPending={loading}
                        progressComponent={<TablePlaceholder />}
                    />
                </Suspense>

                {/* Details Modal */}
                <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Withdrawal Request Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRequest && (
                            <div>
                                <h5>User Details</h5>
                                <p>Name: {selectedRequest.userId.FirstName} {selectedRequest.userId.LastName}</p>
                                <p>Email: {selectedRequest.userId.email}</p>

                                <h5>Bank Details</h5>
                                <p>Bank Name: {selectedRequest.bankAccountId.bankName}</p>
                                <p>Account Holder: {selectedRequest.bankAccountId.accountHolder}</p>
                                <p>Account Number: {selectedRequest.bankAccountId.accountNumber}</p>
                                <p>Branch Code: {selectedRequest.bankAccountId.branchCode}</p>
                                <p>Account Type: {selectedRequest.bankAccountId.accountType}</p>

                                <h5>Transaction Details</h5>
                                <p>Amount: R {selectedRequest.amount.toFixed(2)}</p>
                                <p>Status: {selectedRequest.status}</p>
                                <p>Requested At: {new Date(selectedRequest.createdAt).toLocaleString()}</p>

                                {selectedRequest.bankReference && (
                                    <p>Bank Reference: {selectedRequest.bankReference}</p>
                                )}
                                {selectedRequest.rejectionReason && (
                                    <p>Rejection Reason: {selectedRequest.rejectionReason}</p>
                                )}
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}
