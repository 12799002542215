import React, { useState, useEffect, useRef } from 'react';
import { Card, Alert, Button } from 'react-bootstrap';
import { useTextClass } from '../../hooks/useTextClass';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

export default function BankDeposit() {
    const { textClass, btnClass, getModeClass } = useTextClass();
    const userDetails = useSelector((state) => state.user.userDetails);
    const [paymentData, setPaymentData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [amount, setAmount] = useState('');
    const [paymentWindowClosed, setPaymentWindowClosed] = useState(false);
    const paymentWindowRef = useRef(null);
    const checkIntervalRef = useRef(null);
    const navigate = useNavigate();

    // Effect to automatically open payment window when payment data is received
    useEffect(() => {
        if (paymentData && paymentData.url) {
            // Open the payment in a popup window
            paymentWindowRef.current = window.open(
                paymentData.url,
                'OzowPayment',
                'width=600,height=700,resizable=yes,scrollbars=yes,status=yes'
            );

            // Check if window was blocked by popup blocker
            if (!paymentWindowRef.current || paymentWindowRef.current.closed || typeof paymentWindowRef.current.closed === 'undefined') {
                setError('Payment popup was blocked. Please allow popups for this site and try again.');
            } else {
                // Set up interval to check if window is closed manually
                checkIntervalRef.current = setInterval(() => {
                    if (paymentWindowRef.current.closed) {
                        clearInterval(checkIntervalRef.current);
                        setPaymentWindowClosed(true);
                    } else {
                        // Check if the URL has changed to success or cancel page
                        try {
                            // This will throw an error if cross-origin
                            const currentUrl = paymentWindowRef.current.location.href;

                            // Check if redirected to success or cancel page
                            if (currentUrl.includes('/client/bank-deposit-success')) {
                                clearInterval(checkIntervalRef.current);
                                paymentWindowRef.current.close();
                                navigate('/client/bank-deposit-success' + paymentWindowRef.current.location.search);
                            } else if (currentUrl.includes('/client/bank-deposit-cancel') ||
                                currentUrl.includes('/client/bank-deposit-error')) {
                                clearInterval(checkIntervalRef.current);
                                paymentWindowRef.current.close();
                                navigate('/client/bank-deposit-cancel' + paymentWindowRef.current.location.search);
                            }
                        } catch (e) {
                            // Cross-origin error, can't access URL
                            // This is expected when on the payment provider domain
                        }
                    }
                }, 1000);
            }
        }

        // Cleanup function
        return () => {
            if (checkIntervalRef.current) {
                clearInterval(checkIntervalRef.current);
            }
            if (paymentWindowRef.current) {
                paymentWindowRef.current.close();
            }
        };
    }, [paymentData, navigate]);

    const initiateBankDeposit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            setPaymentWindowClosed(false);
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/bank-deposit/initiate`,
                { amount: parseFloat(amount) },
                { withCredentials: true }
            );
            setPaymentData(response.data);
            setError(null);
        } catch (err) {
            setError('Failed to initiate payment. Please try again later.');
            console.error('Error initiating bank deposit:', err);
        } finally {
            setLoading(false);
        }
    };

    const openPaymentWindow = () => {
        if (paymentData && paymentData.url) {
            paymentWindowRef.current = window.open(
                paymentData.url,
                'OzowPayment',
                'width=600,height=700,resizable=yes,scrollbars=yes,status=yes'
            );

            if (!paymentWindowRef.current || paymentWindowRef.current.closed || typeof paymentWindowRef.current.closed === 'undefined') {
                setError('Payment popup was blocked. Please allow popups for this site and try again.');
            } else {
                setPaymentWindowClosed(false);

                // Set up the same monitoring for the reopened window
                if (checkIntervalRef.current) {
                    clearInterval(checkIntervalRef.current);
                }

                checkIntervalRef.current = setInterval(() => {
                    if (paymentWindowRef.current.closed) {
                        clearInterval(checkIntervalRef.current);
                        setPaymentWindowClosed(true);
                    } else {
                        try {
                            const currentUrl = paymentWindowRef.current.location.href;

                            if (currentUrl.includes('/client/bank-deposit-success')) {
                                clearInterval(checkIntervalRef.current);
                                paymentWindowRef.current.close();
                                navigate('/client/bank-deposit-success' + paymentWindowRef.current.location.search);
                            } else if (currentUrl.includes('/client/bank-deposit-cancel') ||
                                currentUrl.includes('/client/bank-deposit-error')) {
                                clearInterval(checkIntervalRef.current);
                                paymentWindowRef.current.close();
                                navigate('/client/bank-deposit-cancel' + paymentWindowRef.current.location.search);
                            }
                        } catch (e) {
                            // Cross-origin error, can't access URL
                        }
                    }
                }, 1000);
            }
        }
    };

    if (!userDetails.isKyc) {
        return (
            <div className='page'>
                <div className="container mt-4">
                    <Alert variant="warning">
                        <h4>KYC Required</h4>
                        <p>You need to complete KYC verification before making bank deposits.</p>
                        <Link to="/client/kyc" className={`btn btn-warning mt-2`}>
                            Complete KYC
                        </Link>
                    </Alert>
                </div>
            </div>
        );
    }

    return (
        <div className='page'>
            <div className="container">
                <div className="row my-4">
                    <div className="col-12">
                        <h2 className={textClass}>Bank Deposit</h2>
                        <div className="row mt-4">
                            {/* Deposit Form */}
                            <div className="col-md-6">
                                <Card className={getModeClass}>
                                    <Card.Body>
                                        <h4 className={textClass}>Deposit Amount</h4>
                                        <form onSubmit={initiateBankDeposit}>
                                            <div className="mb-3">
                                                <div className="input-group">
                                                    <span className="input-group-text">ZAR</span>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={amount}
                                                        onChange={(e) => setAmount(e.target.value)}
                                                        placeholder="Enter amount"
                                                        min="100"
                                                        required
                                                    />
                                                </div>
                                                <small className="text-muted">Minimum deposit: R100</small>
                                            </div>
                                            <button
                                                type="submit"
                                                className={`btn ${btnClass}`}
                                                disabled={loading}
                                            >
                                                {loading ? 'Processing...' : 'Initiate Payment'}
                                            </button>
                                        </form>

                                        <Alert variant="info" className="mt-4">
                                            <strong>Important:</strong>
                                            <ul className="mb-0">
                                                <li>After clicking "Initiate Payment", a payment window will open automatically</li>
                                                <li>If the payment window is blocked, please allow popups for this site</li>
                                                <li>Deposits typically reflect within 12-24 hours</li>
                                                <li>Minimum deposit amount: ZAR 100.00</li>
                                                <li>For assistance, please contact support</li>
                                            </ul>
                                        </Alert>
                                    </Card.Body>
                                </Card>
                            </div>

                            {/* Payment Section */}
                            <div className="col-md-6">
                                {error && (
                                    <Alert variant="danger">
                                        {error}
                                    </Alert>
                                )}

                                {paymentData && paymentData.url && (
                                    <Card className={getModeClass}>
                                        <Card.Body>
                                            <h4 className={textClass}>Payment Status</h4>
                                            <div className="mt-3">
                                                {paymentWindowClosed ? (
                                                    <Alert variant="warning">
                                                        <p className="mb-0">
                                                            It seems you've closed the payment window. If you haven't completed your payment, you can reopen it.
                                                        </p>
                                                    </Alert>
                                                ) : (
                                                    <Alert variant="success">
                                                        <p className="mb-0">
                                                            Payment window has been opened. Please complete your payment in the new window.
                                                        </p>
                                                    </Alert>
                                                )}

                                                <div className="d-grid gap-2 mt-4">
                                                    <Button
                                                        variant="primary"
                                                        size="lg"
                                                        onClick={openPaymentWindow}
                                                        className={btnClass}
                                                    >
                                                        {paymentWindowClosed ? 'Reopen Payment Window' : 'Open Payment Window Again'}
                                                    </Button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className={`${textClass} small`}>
                                                        Transaction ID: {paymentData.paymentRequestId}
                                                    </p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}