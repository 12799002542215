import axios from 'axios';
import { client } from './helper';

export const getAllCoins = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/coins`, { withCredentials: true });
        if (response.data) {
            return response.data.sort((a, b) => a.name.localeCompare(b.name));
        }
    } catch (error) {
        console.error('Error fetching coins:', error);
    }
};

export const fetchCoins = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/coins`, { withCredentials: true });
        return response.data
            .filter((coin) => coin.coinType === 'crypto')
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((coin) => ({
                value: coin.testnetShortCode,
                label: `${coin.testnetName} (${coin.testnetShortCode})`,
            }));
    } catch (error) {
        console.error('Error fetching coins:', error);
        throw new Error('Failed to load coin options. Please try again.');
    }
};

export const fetchFiatCoins = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/coins`, { withCredentials: true });
        return response.data
            .filter((coin) => coin.coinType === 'fiat')
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((coin) => ({
                value: coin.shortCode,
                label: `${coin.shortCode}`,
            }));
    } catch (error) {
        console.error('Error fetching coins:', error);
        throw new Error('Failed to load coin options. Please try again.');
    }
};

export const fetchTradePairs = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tradePairs`, { withCredentials: true });
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.error('Error fetching tradePairs:', error);
        throw new Error('Failed to load tradePairs. Please try again.');
    }
};

export const createWallet = async (coinType) => {
    try {
        if (coinType) {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/wallet/create-wallet`, {
                coinType,
            }, { withCredentials: true });

            if (response.status === 500) {
                throw new Error('This asset is not supported for crypto deposit, instead use buy crypto.');
            }

            return response.data;
        }
    } catch (error) {
        console.error('Error creating wallet:', error);
        throw new Error(error.message || 'Failed to create wallet.');
    }
};

export const checkWallet = async (vaultId, coinType) => {
    try {
        if (coinType) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wallet/check-wallet/${vaultId}/${coinType}`, {
                withCredentials: true,
            });
            return response.data;
        }
    } catch (error) {
        console.error('Error checking wallet:', error);
        throw new Error('Failed to check wallet.');
    }
};

export const fetchWalletBalance = async (coinType) => {
    try {
        if (coinType) {
            const balanceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/wallet/balance`, {
                params: { coinType },
                withCredentials: true
            });
            return balanceResponse.data.balance;
        }
    } catch (error) {
        console.error('Error fetching wallet balance:', error);
    }
};
export const fetchFiatWalletBalance = async (coinType) => {
    try {
        if (coinType) {
            const balanceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/wallet/fiat-balance`, {
                params: { coinType },
                withCredentials: true
            });
            return balanceResponse.data.balance;
        }
    } catch (error) {
        console.error('Error fetching wallet balance:', error);
    }
};

export const getDepositAddress = async (coinType) => {
    try {
        if (coinType) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wallet/deposit-address/${coinType}`, {
                withCredentials: true
            });
            return response.data;
        }
    } catch (error) {
        throw new Error('Failed to fetch deposit address');
    }
};

export const fetchGasFees = async (symbol, amount) => {
    try {
        if (symbol && amount) {
            const payload = {
                assetId: symbol,
                amount: amount
            };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/transaction/get_estimatedFee`, payload, {
                withCredentials: true
            });
            return response.data?.estimatedFee?.high?.networkFee;
        }
    } catch (error) {
        console.error('Error fetching gas fees:', error);
        return 0;
    }
};

export const fetchBlogs = async () => {
    try {
        const response = await client.getEntries({ content_type: 'blogs' });
        const assets = response.includes?.Asset || [];

        // Helper function to resolve an asset URL by ID
        const resolveImageUrl = (assetId) => {
            const asset = assets.find((a) => a.sys.id === assetId);
            return asset?.fields?.file?.url ? `https:${asset.fields.file.url}` : null;
        };

        return response.items.map((item) => ({
            id: item.sys.id,
            title: item.fields.title,
            slug: item.fields.slug,
            shortDescription: item.fields.shortDescription,
            featureImage: resolveImageUrl(item.fields.featureImage?.sys.id),
            content: item.fields.content,
        }));
    } catch (error) {
        console.error('Error fetching blogs:', error);
        return [];
    }
};

export const fetchBlogBySlug = async (slug) => {
    try {
        const response = await client.getEntries({
            content_type: 'blogs',
            'fields.slug': slug,
        });
        if (response.items.length > 0) {
            const item = response.items[0];
            return {
                title: item.fields.title,
                imgSrc: item.fields.featureImage?.fields?.file?.url,
                authorName: item.fields.authorName,
                date: item.sys.createdAt,
                content: item.fields.content, // Rich text field
            };
        }
        return null;
    } catch (error) {
        console.error('Error fetching blog by slug:', error);
        return null;
    }
};

export const fetchSinglePageContent = async (type_type_id) => {
    try {
        const response = await client.getEntries({
            content_type: type_type_id,
        });
        if (response.items.length > 0) {
            const item = response.items[0];
            return {
                title: item.fields.title,
                lastUpdate: item.fields.lastUpdate,
                content: item.fields.content,
            };
        }
        return null;
    } catch (error) {
        console.error('Error fetching single page content:', error);
        return null;
    }
};

export const fetchTransactionsWithFilters = async (params = {}) => {
    try {
        // Remove empty filters
        Object.keys(params).forEach(key => {
            if (params[key] === '' || params[key] === undefined) {
                delete params[key];
            }
        });

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/wallet/transactions`,
            {
                withCredentials: true,
                params
            }
        );

        // Handle both admin response format (with pagination) and user response format
        if (response.data.transactions && response.data.pagination) {
            return {
                transactions: response.data.transactions,
                pagination: response.data.pagination
            };
        } else {
            // For user view, create pagination object from the array
            return {
                transactions: response.data,
                pagination: {
                    page: 1,
                    limit: response.data.length,
                    total: response.data.length,
                    pages: 1
                }
            };
        }
    } catch (error) {
        console.error("Error fetching transactions:", error);
        throw new Error("Failed to fetch transactions. Please try again later.");
    }
};

export const exportTransactionsToExcel = (data, filename = 'Transactions_Export') => {
    // This function will be implemented in helper.js
};

export const exportTransactionsToCsv = (data, filename = 'Transactions_Export') => {
    // This function will be implemented in helper.js
};

export const exportTransactionsToPdf = (data, filename = 'Transactions_Export') => {
    // This function will be implemented in helper.js
};

// Add this function if it doesn't exist already
export const uploadKycDocument = async (documentType, file, metadata = {}) => {
    try {
        const formData = new FormData();
        formData.append(documentType, file);

        if (metadata) {
            Object.keys(metadata).forEach(key => {
                formData.append(key, metadata[key]);
            });
        }

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/users/me/${documentType}Upload`,
            formData,
            { withCredentials: true }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Add this new function for contact form submission
export const submitContactForm = async (formData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData);
        return response.data;
    } catch (error) {
        throw error;
    }
};
// Add this function for updating user profile
export const updateUserProfile = async (userData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/me`, userData, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

// Add this function for uploading avatar
export const uploadUserAvatar = async (formData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/me/avatarUpload`, formData, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error uploading avatar:', error);
    throw error;
  }
};